import { Menu as HeadlessMenu } from "@headlessui/react";
import { FolderIcon, DotsVerticalIcon } from "@heroicons/react/solid";
import {
  SortAscendingIcon,
  SortDescendingIcon,
  ViewListIcon,
  ViewGridIcon,
} from "@heroicons/react/solid";
import SearchInput from "../../UI/SearchComponent";
import classNames from "classnames";

const SearchHeader = ({ 
  searchTerm, 
  onSearch, 
  showFolderList, 
  onFolderListToggle, 
  onSort, 
  onGroup, 
  sortConfig, 
  isGrouped 
}) => {
  return (
    <div className="border-t border-b border-neutral-200 bg-neutral-50 px-6 py-2 text-sm font-medium text-neutral-500">
      <div className="flex items-center justify-between flex-1">
        <SearchInput
          value={searchTerm}
          onChange={onSearch}
          className="flex-1 mr-2"
        />
        <div className="flex items-center space-x-2">
          <button
            onClick={onFolderListToggle}
            className={classNames(
              "p-2 rounded-md hover:bg-neutral-200 transition-colors",
              showFolderList ? "text-primary-600" : "text-neutral-500"
            )}
            title="Show Folders"
          >
            <FolderIcon className="h-5 w-5" />
          </button>
          <HeadlessMenu as="div" className="relative">
            <HeadlessMenu.Button
              className="p-2 rounded-md hover:bg-neutral-200 transition-colors text-neutral-500"
              title="More Options"
            >
              <DotsVerticalIcon className="h-5 w-5" />
            </HeadlessMenu.Button>
            <HeadlessMenu.Items className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
              <div className="py-1">
                <HeadlessMenu.Item>
                  {({ active }) => (
                    <button
                      onClick={onSort}
                      className={classNames(
                        active ? "bg-neutral-100" : "",
                        "flex items-center px-4 py-2 text-sm text-neutral-700 w-full"
                      )}
                    >
                      {sortConfig.order === "asc" ? (
                        <SortAscendingIcon className="h-5 w-5 mr-2" />
                      ) : (
                        <SortDescendingIcon className="h-5 w-5 mr-2" />
                      )}
                      {sortConfig.order === "asc" ? "Sort Descending" : "Sort Ascending"}
                    </button>
                  )}
                </HeadlessMenu.Item>
                <HeadlessMenu.Item>
                  {({ active }) => (
                    <button
                      onClick={onGroup}
                      className={classNames(
                        active ? "bg-neutral-100" : "",
                        "flex items-center px-4 py-2 text-sm text-neutral-700 w-full"
                      )}
                    >
                      {isGrouped ? (
                        <ViewGridIcon className="h-5 w-5 mr-2" />
                      ) : (
                        <ViewListIcon className="h-5 w-5 mr-2" />
                      )}
                      {isGrouped ? "Ungroup" : "Group"}
                    </button>
                  )}
                </HeadlessMenu.Item>
              </div>
            </HeadlessMenu.Items>
          </HeadlessMenu>
        </div>
      </div>
    </div>
  );
};

export default SearchHeader;
