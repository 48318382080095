import React, { useState, useEffect, useReducer, useRef } from "react";
import { axiosPrivate } from "../../../common/axiosPrivate";
import LoadingSpinner from "../../common/LoadingSpinner";
import { useDebounceValue } from "usehooks-ts";
import SearchInput from "../../../UI/SearchComponent";
import PodcastCard from "../../../UI/PodcastCard";

const FETCH_DATA = "FETCH_DATA";
const CHANGE_PAGE = "CHANGE_PAGE";
const SET_SEARCH_TERM = "SET_SEARCH_TERM";

const initialState = {
  podcasts: [],
  currentPage: 1,
  totalItems: 0,
  itemsPerPage: 50,
  totalPages: 1,
  isLoading: false,
};

const podcastsReducer = (state, action) => {
  switch (action.type) {
    case FETCH_DATA:
      return {
        ...state,
        podcasts: action.payload.items,
        totalItems: action.payload.total,
        currentPage: action.payload.page,
        itemsPerPage: action.payload.size,
        totalPages: action.payload.pages,
        isLoading: false,
        isLoading: false,
      };
    case CHANGE_PAGE:
      return {
        ...state,
        currentPage: action.payload,
        isLoading: true,
      };
    default:
      return state;
  }
};

export default function PodcastSearch({ searchValue, onSearchChange, onSelectPodcast, handleFavoriteOnClick, isLimitReached }) {
  const resultsContainerRef = useRef(null);
  const [state, dispatch] = useReducer(podcastsReducer, initialState);
  const [debouncedSearchValue] = useDebounceValue(searchValue, 500);

  const fetchPodcasts = async (searchTerm, page) => {
    try {
      const response = await axiosPrivate.get(`/podcast/search`, {
        params: {
          query: searchTerm,
          page: page,
          size: state.itemsPerPage,
        },
      });
      dispatch({ type: FETCH_DATA, payload: response.data });
    } catch (error) {
      console.error("Error fetching podcasts: ", error);
      dispatch({
        type: FETCH_DATA,
        payload: { items: [], total: 0, page: 1, size: state.itemsPerPage, pages: 1 },
      });
    }
  };

  useEffect(() => {
    if (debouncedSearchValue) {
      dispatch({ type: CHANGE_PAGE, payload: 1 });
      fetchPodcasts(debouncedSearchValue, 1);
    }
  }, [debouncedSearchValue]);

  const handlePageChange = async (newPage) => {
    if (newPage >= 1 && newPage <= state.totalPages) {
      dispatch({ type: CHANGE_PAGE, payload: newPage });
      fetchPodcasts(debouncedSearchValue, newPage);
      resultsContainerRef.current?.scrollIntoView();
    }
  };

  const handleSearch = (e) => {
    if (!isLimitReached) {
      const newValue = e.target.value;
      onSearchChange(newValue);
      if (!newValue) {
        dispatch({
          type: FETCH_DATA,
          payload: { items: [], total: 0, page: 1, size: state.itemsPerPage, pages: 1 },
        });
      }
    }
  };

  return (
    <div className="h-full overflow-y-auto px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <SearchInput
          value={searchValue}
          onChange={handleSearch}
          className="mb-6 w-full"
          placeholder="Search podcasts..."
          disabled={isLimitReached || state.isLoading}
        />
        {state.isLoading && (
          <div className="bg-white shadow-md rounded-lg overflow-hidden transition-all duration-300 mt-6 mb-8 sm:mb-6">
            <div className="p-8">
              <div className="flex flex-col items-center justify-center">
                <div className="mb-4">
                  <LoadingSpinner />
                </div>
                <h3 className="text-lg text-neutral-400 text-center">Searching podcasts...</h3>
                <p className="mt-2 text-sm text-neutral-500 text-center">
                  Please wait while we find the best matches for you
                </p>
              </div>
            </div>
          </div>
        )}
        {!state.isLoading && state.podcasts.length === 0 && (
          <div className="bg-white shadow-md rounded-lg overflow-hidden transition-all duration-300 mt-6 mb-8 sm:mb-6">
            <div className="p-8">
              <div className="flex flex-col items-center justify-center">
                <svg className="h-16 w-16 text-neutral-300 mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0-11V3m0 0a7 7 0 017 7m-7-7a7 7 0 00-7 7"
                  />
                </svg>
                <h3 className="text-lg text-neutral-400 text-center">
                  {searchValue ? "No podcasts found." : "Search for your favorite podcasts..."}
                </h3>
                <p className="mt-2 text-sm text-neutral-500 text-center">
                  {searchValue
                    ? "Try adjusting your search terms or try a different query"
                    : "Type in the search box above to discover amazing podcasts"}
                </p>
              </div>
            </div>
          </div>
        )}
        <div ref={resultsContainerRef} className="grid gap-6">
          {state.podcasts.map((podcast, index) => (
            <PodcastCard
              key={index}
              feed={podcast}
              handleFavorite={handleFavoriteOnClick}
              onClick={isLimitReached ? null : () => onSelectPodcast(podcast)}
            />
          ))}
        </div>
        <nav
          aria-label="Pagination"
          className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-6">
          <div className="hidden sm:block">
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{(state.currentPage - 1) * state.itemsPerPage + 1}</span> to{" "}
              <span className="font-medium">{Math.min(state.currentPage * state.itemsPerPage, state.totalItems)}</span>{" "}
              of <span className="font-medium">{state.totalItems}</span> results
            </p>
          </div>
          <div className="flex flex-1 justify-between sm:justify-end">
            <button
              onClick={() => handlePageChange(state.currentPage - 1)}
              disabled={state.isLoading || state.currentPage === 1}
              className="relative inline-flex items-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-neutral-700 border border-neutral-200
              hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200">
              Previous
            </button>
            <button
              onClick={() => handlePageChange(state.currentPage + 1)}
              disabled={state.isLoading || state.currentPage === state.totalPages}
              className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1
              ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 disabled:opacity-50 disabled:cursor-not-allowed">
              Next
            </button>
          </div>
        </nav>
      </div>
    </div>
  );
}
