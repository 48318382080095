import { AuthLayout } from "./Landing/AuthLayout";
import { Button } from "./Landing/Button";
import { TextField } from "./Landing/Fields";
import classNames from "classnames";
import PodcastIcon from "../UI/PodcastIcon";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useEffect, useRef, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import useAuth from "../hooks/useAuth";
import { Toaster } from "react-hot-toast";
import { axiosPrivate } from "../common/axiosPrivate";
import { axiosPublic } from "../common/axiosPublic";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import SEO from "./SEO";
import { getTracker } from "../common/tracker";
import { Turnstile } from "@marsidev/react-turnstile";

export default function Login() {
  const [apiError, setApiError] = useState();
  const [turnstileToken, setTurnstileToken] = useState(null);
  const [turnstileError, setTurnstileError] = useState(null);
  const turnstileRef = useRef(null);

  const { setAuth } = useAuth();

  const navigate = useNavigate();

  const googleLogin = useGoogleLogin({
    flow: "implicit",
    onSuccess: async (response) => {
      try {
        const result = await axiosPublic.post("/auth/google/login", {
          credential: response.access_token,
        });
        if (result.status === 200) {
          const session = result.data;
          localStorage.setItem("session", JSON.stringify(session));
          setAuth({
            token: session.access_token,
            session: session,
            loading: false,
          });
          getUserData(session.access_token);
        }
      } catch (error) {
        console.error(error);
        if (error.response.status === 404) {
          toast.error("Account does not exist. Please sign up first.");
        } else {
          toast.error("Error signing in with Google");
        }
      }
    },
    onError: () => {
      toast.error("Google sign in failed");
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const getUserData = async (token) => {
    try {
      const response = await axiosPrivate.get("/users/me");
      const user = response.data;
      localStorage.setItem("user", JSON.stringify(user));

      const tracker = getTracker();
      tracker.setUserID("" + user.email);
      tracker.setMetadata("subscription", "" + user.subscription_status);
      tracker.setMetadata("plan", "" + user.subscription_tier);
      setAuth({
        token: token,
        user: user,
        loading: false,
      });
      navigate("/library", { replace: true });
    } catch (error) {
      console.log(error);
      toast.error("Error communicating with the server");
    }
  };

  const onFormSubmit = async (data) => {
    if (!turnstileToken) {
      setTurnstileError("Please complete the verification.");
      return;
    }

    const response = await axiosPublic
      .post("/auth/login", { ...data, turnstileToken })
      .then((response) => {
        // Handle successful request
        console.log(response.data);
        if (response.status === 200) {
          const session = response.data;
          localStorage.setItem("session", JSON.stringify(session));
          setAuth({
            token: session.access_token,
            session: session,
            loading: false,
          });
          getUserData(session.access_token);
        }
        return response;
      })
      .catch((error) => {
        // if the login is not successful provide an error message with toast
        if (error.response.status === 400 && error.response.data.detail == "Incorrect Email or Password") {
          toast.error("Incorrect Email or Password");
          setApiError(error);
          setAuth({
            token: null,
            session: null,
            loading: false,
          });
        }
        if (error.response.status === 404) {
          toast.error("Account does not exist");
          setApiError(error);
          setAuth({
            token: null,
            session: null,
            loading: false,
          });
        }
        if (error.response.status === 401 && error.response.data.detail == "Please verify your email address") {
          toast.error("Please verify your email address");
          setApiError(error);
          setAuth({
            token: null,
            loading: false,
          });
        }
        return error.response;
      })
      .finally(() => {
        // always executed
        if (turnstileRef.current) {
          turnstileRef.current.reset();
        }
        setTurnstileToken(null);
        setTurnstileError(null);
      });
  };
  const onErrors = (errors) => console.error(errors);
  return (
    <>
      <SEO title="PodScribe.IO | Login" />
      <AuthLayout>
        <Toaster position="top-right" />
        <div className="flex flex-col">
          <Link to="/" aria-label="Home">
            <div className="flex items-center justify-center">
              <PodcastIcon className="h-10 w-10 fill-primary-600" />
              <h1 className="ml-2 text-4xl font-bold text-primary-900 bg-gradient-to-r from-primary-600 to-primary-900 bg-clip-text text-transparent">
                PodScribe.IO
              </h1>
            </div>
          </Link>
          <div className="mt-20">
            <h2 className="text-lg font-semibold text-secondary-800">Sign in to your account</h2>
            <p className="mt-2 text-sm text-primary-700">
              Don’t have an account?{" "}
              <Link to="/register" className="font-medium text-blue-600 hover:underline">
                Sign up
              </Link>{" "}
              for a free trial.
            </p>
          </div>

          <form onSubmit={handleSubmit(onFormSubmit, onErrors)} className="mt-10 grid grid-cols-1 gap-y-8">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-primary-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-primary-300 rounded-md shadow-sm placeholder-primary-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  {...register("email", { required: "The email is required" })}
                />
                <span className="text-support-red-500">{errors?.email && errors.email.message}</span>
              </div>
            </div>
            <div>
              <label htmlFor="password" className="mb-3 block text-sm font-medium text-primary-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-primary-300 rounded-md shadow-sm placeholder-primary-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  {...register("password", {
                    required: "The password is required",
                  })}
                />
                <span className="text-support-red-500">{errors?.password && errors.password.message} </span>
              </div>
            </div>
            <div className="mt-4 flex flex-col items-center justify-between">
              <Turnstile
                siteKey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
                onSuccess={(token) => setTurnstileToken(token)}
                onError={() => setTurnstileError("Turnstile verification failed. Please try again.")}
                onExpire={() => setTurnstileToken(null)}
                options={{
                  theme: "light",
                }}
                ref={turnstileRef}
              />
              {turnstileError && <span className="text-red-500 text-sm">{turnstileError}</span>}
            </div>
            <div className="space-y-4">
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                Sign in
              </button>

              {/* Divider */}
              <div className="relative my-6">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Or</span>
                </div>
              </div>

              <button
                type="button"
                onClick={() => googleLogin()}
                className="w-full flex justify-center items-center gap-2 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                <svg className="h-5 w-5" viewBox="0 0 24 24">
                  <path
                    fill="#4285F4"
                    d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                  />
                  <path
                    fill="#34A853"
                    d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                  />
                  <path
                    fill="#FBBC05"
                    d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                  />
                  <path
                    fill="#EA4335"
                    d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                  />
                </svg>
                Sign in with Google
              </button>
            </div>
          </form>
        </div>
      </AuthLayout>
    </>
  );
}
