import { useEffect, useReducer, useRef, useState } from "react";
import { ChevronRightIcon, ChevronUpIcon } from "@heroicons/react/solid";
import CardPagination from "../../UI/Pagination";
import { ArrowRightIcon, PlayIcon } from "@heroicons/react/solid";
import { Highlight } from "../../common/utils";

const initialState = {
  feedData: [],
  filteredFeedData: [],
  totalItems: 0,
  startIndex: 0,
  currentPage: 1,
  totalPages: 0,
  pageSize: 50,
  searchTerm: "",
  show_search_results: false,
  searchData: [],
  scrollToParagraphIndex: null,
};

const paginationReducer = (currntState, action) => {
  if (action.type === "INIT") {
    return {
      ...currntState,
      feedData: action.feedData,
      totalItems: action.feedData.length,
      totalPages: action.totalPages,
    };
  }
  if (action.type === "PAGINATION") {
    return {
      ...currntState,
      show_search_results: false,
      currentPage: action.currentPage,
      startIndex: (action.currentPage - 1) * currntState.pageSize,
      filteredFeedData: currntState.feedData.slice(
        (action.currentPage - 1) * currntState.pageSize,
        action.currentPage * currntState.pageSize
      ),
      scrollToParagraphIndex: action.scrollToParagraphIndex,
    };
  }
  if (action.type === "SEARCH") {
    const allParagraphs = currntState.feedData.map((item, paragraphIndex) => ({
      text: item.sentences.map((sentence) => sentence.text).join(" "),
      episodeId: item.episodeId,
      paragraphIndex: paragraphIndex,
      start: item.start,
      end: item.end,
    }));

    const matchingText = allParagraphs
      .filter((item) => item.text.toLowerCase().includes(action.searchTerm.toLowerCase()))
      .map((item) => {
        const origin = {
          episodeId: item.episodeId,
          paragraphIndex: item.paragraphIndex,
          start: item.start,
          end: item.end,
          page: Math.max(1, Math.ceil((item.paragraphIndex + 1) / currntState.pageSize)),
          pageRelativeParagraphIndex: item.paragraphIndex % currntState.pageSize,
        };
        return { text: item.text, origin };
      });

    return {
      ...currntState,
      show_search_results: true,
      searchData: matchingText,
    };
  }
};

export default function Transcript(props) {
  const [paginationState, dispatch] = useReducer(paginationReducer, initialState);
  const [showScrollTop, setShowScrollTop] = useState(true);

  const scrollToTop = () => {
    const element = document.getElementById("message-content-scroll-target");
    if (element) {
      element.scrollTo({
        top: element.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const onNext = () => {
    if (paginationState.currentPage < paginationState.totalPages) {
      const element = document.getElementById("message-content-scroll-target");
      if (element) {
        element.scrollTo({
          top: element.offsetTop,
          behavior: "smooth",
        });
      }

      dispatch({
        type: "PAGINATION",
        currentPage: paginationState.currentPage + 1,
      });
    }
  };

  const onPrevious = () => {
    if (paginationState.currentPage > 1) {
      const element = document.getElementById("message-content-scroll-target");
      if (element) {
        element.scrollTo({
          top: element.offsetTop,
          behavior: "smooth",
        });
      }

      dispatch({
        type: "PAGINATION",
        currentPage: paginationState.currentPage - 1,
      });
    }
  };

  const onPageChange = (page, paragraphIndex) => {
    console.log("page", page, "paragraphIndex", paragraphIndex);
    dispatch({
      type: "PAGINATION",
      currentPage: page,
      scrollToParagraphIndex: paragraphIndex,
    });
  };

  // Handle change of props.searchTerm
  useEffect(() => {
    if (props.searchTerm !== "") {
      dispatch({ type: "SEARCH", searchTerm: props.searchTerm });
      console.log("searchTerm", props.searchTerm);
    } else {
      // reset to initial state
      dispatch({
        type: "PAGINATION",
        currentPage: paginationState.currentPage,
      });
      console.log("paginationState after search box empty", paginationState.totalPages, paginationState.currentPage);
    }
  }, [props.searchTerm]);

  useEffect(() => {
    if (props.data !== null && props.data.length > 0) {
      const TotalItems = props.data.length;
      const TotalPages = Math.ceil(TotalItems / initialState.pageSize);
      dispatch({ type: "INIT", feedData: props.data, totalPages: TotalPages });
      dispatch({ type: "PAGINATION", currentPage: 1 });
    }
  }, [props.data]);

  // Handle scrolling when page changes
  useEffect(() => {
    const element = document.getElementById("message-content-scroll-target");
    if (element) {
      element.scrollTo({
        top: element.offsetTop,
        behavior: "smooth",
      });
    }
  }, [paginationState.currentPage]);

  useEffect(() => {
    const container = document.getElementById("message-content-scroll-target");
    const paragraphElement = document.getElementById(`paragraph-${paginationState.scrollToParagraphIndex}`);
    if (container && paragraphElement) {
      const containerRect = container.getBoundingClientRect();
      const paragraphRect = paragraphElement.getBoundingClientRect();
      const relativeTop = paragraphRect.top - containerRect.top + container.scrollTop;

      container.scrollTo({
        top: relativeTop,
        behavior: "smooth",
      });
    }
  }, [paginationState.scrollToParagraphIndex]);

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 px-4 py-2 mt-6">
        {paginationState.show_search_results ? (
          <div className="space-y-6">
            <h3 className="text-lg font-semibold text-primary-600 border-b pb-2">
              Search Results ({paginationState.searchData.length} matches)
            </h3>
            {paginationState.searchData.map((searchItem, searchItemIdx) => (
              <div
                key={searchItemIdx}
                className="bg-white rounded-lg shadow-sm border border-neutral-100 p-4 hover:shadow-md transition-shadow duration-200">
                <div className="flex items-center justify-between mb-3">
                  <div className="flex items-center space-x-3">
                    <span className="text-sm font-medium text-neutral-500">
                      {Math.floor(searchItem.origin.start / 60)}:
                      {(searchItem.origin.start % 60).toFixed(0).padStart(2, "0")} -{" "}
                      {Math.floor(searchItem.origin.end / 60)}:
                      {(searchItem.origin.end % 60).toFixed(0).padStart(2, "0")}
                    </span>
                    <button
                      onClick={() => onPageChange(searchItem.origin.page, searchItem.origin.pageRelativeParagraphIndex)}
                      className="inline-flex items-center text-sm text-primary-600 hover:text-primary-700">
                      Go to context
                      <ChevronRightIcon className="ml-1 h-4 w-4" />
                    </button>
                  </div>
                </div>
                <div className="prose prose-sm max-w-none">
                  <Highlight
                    text={searchItem.text}
                    highlight={props.searchTerm}
                    context={100}
                    className="text-neutral-700"
                  />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <nav aria-label="transcript" className="space-y-6">
            {paginationState.filteredFeedData.map((activityItem, activityItemIdx) => (
              <div
                key={activityItemIdx}
                id={`paragraph-${activityItemIdx}`}
                className="bg-white rounded-lg shadow-sm border border-neutral-100 p-4 hover:shadow-md transition-shadow duration-200">
                <div className="flex items-center space-x-3 mb-3">
                  <span className="text-sm font-medium text-neutral-500">
                    {Math.floor(activityItem.start / 60)}:{(activityItem.start % 60).toFixed(0).padStart(2, "0")} -{" "}
                    {Math.floor(activityItem.end / 60)}:{(activityItem.end % 60).toFixed(0).padStart(2, "0")}
                  </span>
                  <button
                    onClick={() => props.SelectSegmentHandler(activityItem.start)}
                    className="inline-flex items-center px-2 py-1 text-xs font-medium text-primary-600 bg-primary-50 rounded-full hover:bg-primary-100 transition-colors duration-200">
                    <PlayIcon className="h-4 w-4 mr-1" />
                    Play
                  </button>
                </div>
                <div className="prose prose-sm max-w-none text-neutral-700">
                  {activityItem.sentences?.map((item, itemIdx) => (
                    <span key={itemIdx}>{item.text} </span>
                  ))}
                </div>
              </div>
            ))}
          </nav>
        )}
      </div>

      {showScrollTop && !paginationState.show_search_results && (
        <div className="flex justify-end px-4 py-2">
          <button
            onClick={scrollToTop}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-primary-600 bg-primary-50 
                     rounded-md hover:bg-primary-100 transition-colors duration-200"
            aria-label="Scroll to top">
            <ChevronUpIcon className="h-5 w-5 mr-1" />
            Back to top
          </button>
        </div>
      )}

      {!paginationState.show_search_results && (
        <div className="bg-white py-3 px-4">
          <CardPagination
            TotalResults={paginationState.totalItems}
            ItemsPerPage={paginationState.pageSize}
            pageSize={paginationState.pageSize}
            CurrentPage={paginationState.currentPage}
            onNext={onNext}
            onPrevious={onPrevious}
          />
        </div>
      )}
    </div>
  );
}
