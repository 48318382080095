/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          rose: colors.rose,
        },
      },
    },
  }
  ```
*/
import { useState, useEffect } from "react";
import { DocumentTextIcon, MapIcon, DocumentDuplicateIcon, PencilIcon } from "@heroicons/react/outline";
import InfoMsgAction from "../../UI/InfoMsgAction";
import CardHeadingSearch from "../../UI/CardHeadingSearch";
import useAuth from "../../hooks/useAuth";
import Editor from "./Editor";
import Transcript from "./Transcript";
import { axiosPrivate } from "../../common/axiosPrivate";
import React from "react";
import Summary from "./Summary";
import { formatDistanceToNow, differenceInSeconds } from "date-fns";
import Button from "../../UI/Button";
import { useDebounceValue } from "usehooks-ts";
import MindMap from "./MindMap";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function InboxContent(props) {
  const { auth } = useAuth();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [EditorSaveContent, setEditorSaveContent] = useState(null);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [saveError, setSaveError] = useState(null);

  const tabs = [
    { name: "Summary", icon: DocumentTextIcon },
    { name: "MindMap", icon: MapIcon },
    { name: "Transcript", icon: DocumentDuplicateIcon },
    { name: "Notes", icon: PencilIcon },
  ];

  // Tab state
  const [selectedTab, setSelectedTab] = useState("Summary");
  // SearchTerm state
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useDebounceValue("", 300);
  const [lastSaved, setLastSaved] = useState(null);
  const [saveNow, setSaveNow] = useState(false);

  // Save notes to API
  const handleEditorSave = async (content) => {
    setIsLoading(true);
    try {
      setLastSaved(new Date());
      setEditorSaveContent(content);
      setSaveNow(false);
    } catch (error) {
      console.error("Failed to save:", error);
      setSaveError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnDemandSave = () => {
    setSaveNow(true);
  };

  useEffect(() => {
    setLastSaved(null);
  }, [props.selectedEpisodeState]);

  useEffect(() => {
    if (saveError) {
      // Show error toast/notification
      setTimeout(() => setSaveError(null), 5000);
    }
  }, [saveError]);

  useEffect(() => {
    if (EditorSaveContent) {
      props.selectedEpisodeState.episode.note = EditorSaveContent.data;
      console.log("EditorSaveContent", EditorSaveContent);
      // send notes to backend using axios
      let method = "post";
      let url = `/users/notes`;
      let body = {
        data: EditorSaveContent.data,
        episode_id: EditorSaveContent.episode_id,
      };

      axiosPrivate
        .post(url, body)
        .then((res) => {
          setResponse(res.data);
          console.log(`Note saved successfully for ${EditorSaveContent.title}!`);
          console.log("Response", res.data);
        })
        .catch((err) => {
          setError(err);
          console.log("Error", err);
        })
        .finally(() => {
          //setloading(false);
        });
    }
  }, [EditorSaveContent]);

  const onSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    setDebouncedSearchValue(searchTerm);
  };
  // Handle tab selection (onSelect)
  const onSelect = (selectedTab) => {
    console.log("selectedTab", selectedTab);
    setSelectedTab(selectedTab);
    if (selectedTab === "Transcript") {
      setShowSearchBar(true);
    } else {
      setShowSearchBar(false);
    }
  };

  const renderContent = () => {
    switch (selectedTab) {
      case "Summary":
        return props.selectedEpisodeState.episode.is_summarized ? (
          <div
            className="flex-1 flex flex-col items-center justify-between 
                         border border-neutral-100 rounded-lg shadow-lg bg-white">
            <Summary
              selectedEpisodeState={props.selectedEpisodeState}
              SelectSegmentHandler={props.SelectSegmentHandler}
            />
          </div>
        ) : (
          <InfoMsgAction
            msg="Podcast summary is not available"
            action="Transcribe"
            onClick={props.HandleTranscribeEpisode}
          />
        );

      case "MindMap":
        return props.selectedEpisodeState.episode.is_summarized ? (
          <div className="flex-1 border border-neutral-100 rounded-lg shadow-lg bg-white">
            <MindMap selectedEpisodeState={props.selectedEpisodeState} />
          </div>
        ) : (
          <InfoMsgAction
            msg="Podcast summary is not available"
            action="Transcribe"
            onClick={props.HandleTranscribeEpisode}
          />
        );

      case "Transcript":
        return props.selectedEpisodeState.episode.transcription ? (
          <div className="flex-1 border border-neutral-100 rounded-lg shadow-lg bg-white">
            <Transcript
              data={props.selectedEpisodeState.episode.transcription.paragraphs.paragraphs}
              searchTerm={debouncedSearchValue}
              SelectSegmentHandler={props.SelectSegmentHandler}
            />
          </div>
        ) : (
          <InfoMsgAction
            msg="Podcast not yet transcribed!"
            action="Transcribe"
            onClick={props.HandleTranscribeEpisode}
          />
        );

      case "Notes":
        return (
          <div className="flex-1 flex flex-col space-y-4">
            <div className="flex-1 rounded-lg shadow-lg bg-white">
              <Editor data={props.selectedEpisodeState} onChange={handleEditorSave} saveNow={saveNow} />
            </div>
            <div className="flex items-center space-x-4 px-4">
              <Button
                onClick={handleOnDemandSave}
                className="inline-flex items-center px-4 py-2 border border-transparent 
                          text-sm font-medium rounded-md shadow-sm text-white 
                          bg-primary-600 hover:bg-primary-700 focus:outline-none 
                          focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                Save Notes
              </Button>
              {lastSaved && (
                <span className="text-sm text-neutral-500">
                  Last saved{" "}
                  {differenceInSeconds(new Date(), lastSaved) < 10
                    ? "just now"
                    : `${formatDistanceToNow(lastSaved)} ago`}
                </span>
              )}
            </div>
          </div>
        );
    }
  };

  return (
    <div className="flex-1 flex flex-col space-y-4 mb-12">
      <div className="bg-white shadow-sm border-b border-neutral-200">
        <CardHeadingSearch
          title="Transcript"
          onSearch={onSearch}
          onSelect={onSelect}
          showSearchBar={showSearchBar}
          tabs={tabs}
          selectedTab={selectedTab}
        />
      </div>

      <div className="flex-1 px-4">{renderContent()}</div>
    </div>
  );
}
