import React, { useState } from "react";
import { HeartIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { HeartIcon as HeartIconOutlined, ExternalLinkIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import Img from "./Img";
import Button from "./Button";

export default function PodcastCard({ feed, onClick }) {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  return (
    <div className="bg-white border border-neutral-100 shadow-sm rounded-lg overflow-hidden transition-all duration-300 hover:shadow-md group mt-6 mb-8 sm:mb-6 relative flex flex-col items-center sm:flex-row sm:items-start">
      <div className="p-6 w-full flex flex-col items-center sm:items-start">
        <div className="flex flex-col items-center sm:flex-row sm:items-start w-full">
          <div className="flex-shrink-0 mb-4 sm:mb-0 sm:mr-4 flex justify-center">
            <Img
              src={feed.image}
              alt={feed.title}
              className="h-24 w-24 sm:h-32 sm:w-32 rounded-lg object-cover shadow-sm mx-auto sm:mx-0 transition-transform duration-300 group-hover:scale-[1.02]"
            />
          </div>
          <div className="flex-grow text-center sm:text-left">
            <div className="flex flex-col sm:flex-row justify-between items-center sm:items-start w-full">
              <div className="group mb-2 sm:mb-0">
                <h4 className="text-lg font-bold text-neutral-900 group-hover:text-primary-600 transition-colors duration-200 flex items-center justify-center sm:justify-start">
                  {feed.title.length > 50 ? feed.title.substring(0, 40) + "..." : feed.title}
                </h4>
              </div>
            </div>
            <h4 className="text-sm font-medium text-neutral-500 text-center sm:text-left mt-2">
              by <span className="text-primary-600">{feed.author}</span>
            </h4>
            <div className="flex flex-wrap mt-2 gap-1 justify-center sm:justify-start w-full">
              {feed.categories &&
                Object.values(feed.categories).map((category, i) => (
                  <span
                    key={i}
                    className="inline-flex items-center rounded-full bg-primary-50 px-2.5 py-0.5 text-xs font-medium text-primary-600 ring-1 ring-inset ring-primary-100 transition-colors duration-200 hover:bg-primary-100">
                    {category}
                  </span>
                ))}
            </div>
          </div>
        </div>
        <p className="mt-4 text-sm text-neutral-600 text-center sm:text-left break-words leading-relaxed">
          {isDescriptionExpanded
            ? feed.description
            : feed.description.length > 200
            ? `${feed.description.substring(0, 197)}...`
            : feed.description}
        </p>
        {feed.description.length > 200 && (
          <button
            onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
            className="text-primary-600 hover:text-primary-700 text-sm mt-2 font-medium transition-colors duration-200"
          >
            {isDescriptionExpanded ? "Hide" : "Show"} more
          </button>
        )}
        <div className="mt-4 self-center sm:absolute sm:top-4 sm:right-4">
          <Button 
            onClick={() => onClick(feed)} 
            className="py-2 px-4 text-sm font-medium bg-primary-600 text-white rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition-all duration-200 flex items-center gap-2"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
            </svg>
            View Episodes
          </Button>
        </div>
      </div>
    </div>
  );
}
