// Library that will fetch the users Library from the backend and display it.
// top level component to show the podcasts, the episodes and the player

import React, { useState, useEffect, useReducer } from "react";
import useFetch from "../hooks/useFetch";
import useAuth from "../hooks/useAuth";
import useLibraryData from "../hooks/useLibraryData";
import { FadeLoader } from "react-spinners";
import toast from "react-hot-toast";
import { Navigate, Outlet, Route, useOutletContext, useParams } from "react-router-dom";
import Inbox from "./Library/Inbox";
import InboxMessage from "./Library/InboxMessage";
import EmptyInboxMessage from "./Library/EmptyInboxMessage";
import InboxContent from "./Library/InboxContent";
import CardHeadingAction from "../UI/CardHeading";
import axios from "axios";
import InboxSidebar from "./Library/MobileInbox";
import { axiosPrivate } from "../common/axiosPrivate";
import { useNavigate } from "react-router-dom";
import Modal from "../UI/Modal";
import Search from "./Library/Search";
import SEO from "./SEO";
import ActionModalDanger from "../UI/ActionModalDanger";

const initialStateSelectedEpisodeReducer = {
  episode: null, // the episode object
  episode_id: null, // the episode id
  is_transcribed: false,
  is_summarized: false,
  is_processing: false,
  transcription_data: null, // the transFcription data
  note_data: null, // the note data object
  save_note_data: null, // the note data object
};

const selectedEpisodeReducer = (state, action) => {
  switch (action.type) {
    case "INIT":
      return {
        ...state,
        episode: action.episode,
        episode_id: action.episode.id,
        is_transcribed: action.episode.is_transcribed,
        is_summarized: action.episode.is_summarized,
        is_processing: action.episode.is_processing,
        state: action.episode.state,
        transcription_data: action.episode.transcription,
      };
    case "REFRESH_DATA":
      return {
        ...state,
        episode: action.episode,
        episode_id: action.episode.id,
        is_transcribed: action.episode.is_transcribed,
        is_summarized: action.episode.is_summarized,
        is_processing: action.episode.is_processing,
        state: action.episode.state,
        transcription_data: action.episode.transcription,
      };
    case "SET_EPISODE":
      return {
        ...state,
        episode: action.episode,

        episode_id: action.episode.id,
        is_transcribed: action.episode.is_transcribed,
        is_summarized: action.episode.is_summarized,
        is_processing: action.episode.is_processing,
        state: action.episode.state,
        transcription_data: action.transcription_data,
      };
    case "DE_SELECT_EPISODE":
      return {
        ...state,
        episode: null,
        episode_id: null,
        is_transcribed: false,
        transcription_data: null,
        note_data: null,
        save_note_data: null,
      };
  }
};

// ****** Library Component ******

export default function Library(props) {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { episodeId } = useParams();
  const [FetchedData, setFetchedData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedEpisodeState, dispatchSelectedEpisodeState] = useReducer(
    selectedEpisodeReducer,
    initialStateSelectedEpisodeReducer
  );
  const { LibraryData, setLibraryData, refreshLibraryEpisode, removeLibraryEpisode } = useLibraryData();
  const [selectedEpisodeEditorData, setSelectedEpisodeEditorData] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  // state related to open the Search Modal
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [episodeToDelete, setEpisodeToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [filteredEpisodeList, setFilteredEpisodeList] = useState([]);

  const handleOpenSearchModal = () => {
    setOpenSearchModal(true);
  };

  // Data is fetched and accessed from LibraryData context

  // fix for SimpleMDE
  window.addEventListener(
    "scroll",
    (event) => {
      // Your scroll event handler code here
    },
    { passive: true }
  );

  // Handle episode selection and navigation
  const SelectEpisodeHandler = (id, direction = null) => {
    // If direction is specified, use filtered list for navigation
    if (direction) {
      const currentIndex = filteredEpisodeList.findIndex((episode) => episode.id === id);
      let targetEpisode;

      if (direction === "next" && currentIndex < filteredEpisodeList.length - 1) {
        targetEpisode = filteredEpisodeList[currentIndex + 1];
      } else if (direction === "previous" && currentIndex > 0) {
        targetEpisode = filteredEpisodeList[currentIndex - 1];
      }

      if (targetEpisode) {
        dispatchSelectedEpisodeState({
          type: "SET_EPISODE",
          episode: targetEpisode,
        });
      }
    } else {
      // If no direction specified (direct selection), use full episode list
      const targetEpisode = LibraryData.episodes.find((episode) => episode.id === id);
      if (targetEpisode) {
        dispatchSelectedEpisodeState({
          type: "SET_EPISODE",
          episode: targetEpisode,
        });
      }
    }
  };

  useEffect(() => {
    // sync changes from LibraryData to selectedEpisodeState
    if (LibraryData && !LibraryData.loading && LibraryData.episodes) {
      console.log("Changed LibraryData, syncing with selectedEpisodeState..");
      console.log("Selected Episode ID:", selectedEpisodeState.episode_id);
      console.log("Episode ID from URL:", episodeId);

      if (LibraryData.episodes.length > 0) {
        if (episodeId) {
          // If episodeId is provided in the URL, select that episode
          console.log("Selected Episode ID from URL:", episodeId);
          const episodeToSelect = LibraryData.episodes.find((episode) => episode.id == episodeId);
          if (episodeToSelect) {
            dispatchSelectedEpisodeState({
              type: "SET_EPISODE",
              episode: episodeToSelect,
            });
            navigate("/library", { replace: true });
          } else {
            // If the episodeId is not found, redirect to the main library page
            navigate("/library", { replace: true });
          }
        } else if (selectedEpisodeState.episode) {
          // If selectedEpisodeState.episode is already set, find the updated episode in LibraryData
          const updatedEpisode = LibraryData.episodes.find((episode) => episode.id === selectedEpisodeState.episode.id);
          if (updatedEpisode) {
            dispatchSelectedEpisodeState({
              type: "SET_EPISODE",
              episode: updatedEpisode,
            });
          }
        } else {
          // If selectedEpisodeState.episode is not set and no episodeId is provided, initialize it with the first episode in LibraryData
          dispatchSelectedEpisodeState({
            type: "INIT",
            episode: LibraryData.episodes[0],
          });
        }
      } else {
        // If the library is empty, deselect any episode and show the modal
        dispatchSelectedEpisodeState({
          type: "DE_SELECT_EPISODE",
        });
        //setShowModal(true);
      }
    }
  }, [LibraryData, episodeId]);

  // Handle initiating episode removal
  const RemoveEpisodeHandler = (feedId, episode_id) => {
    const episodeToRemove = LibraryData.episodes.find((episode) => episode.id === episode_id);
    setEpisodeToDelete({ feedId, episode_id, title: episodeToRemove?.title });
    setIsDeleteModalOpen(true);
  };

  // Handle confirming episode removal
  const handleConfirmDelete = () => {
    const { feedId, episode_id } = episodeToDelete;

    // remove the episode from the library
    removeLibraryEpisode(feedId, episode_id);

    // Get the updated episodes list after removal
    const updatedEpisodes = LibraryData.episodes.filter((episode) => episode.id !== episode_id);

    if (updatedEpisodes.length === 0) {
      // If no episodes left, deselect the episode
      dispatchSelectedEpisodeState({
        type: "DE_SELECT_EPISODE",
      });
    } else {
      // If the removed episode was the selected one, select a new episode
      if (selectedEpisodeState.episode_id === episode_id) {
        const indexOfRemovedEpisode = LibraryData.episodes.findIndex((episode) => episode.id === episode_id);
        let newSelectedEpisode;

        if (indexOfRemovedEpisode === LibraryData.episodes.length - 1) {
          // If the removed episode was the last one, select the new last episode
          newSelectedEpisode = updatedEpisodes[updatedEpisodes.length - 1];
        } else {
          // Otherwise, select the next episode in the list
          newSelectedEpisode = updatedEpisodes[indexOfRemovedEpisode];
        }

        dispatchSelectedEpisodeState({
          type: "SET_EPISODE",
          episode: newSelectedEpisode,
        });
      }
    }

    // Close the modal and clear the episode to delete
    setIsDeleteModalOpen(false);
    setEpisodeToDelete(null);
  };

  if (LibraryData && LibraryData.loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FadeLoader color={"#fca311"} loading={LibraryData.loading} size={150} />
      </div>
    );
  }

  return (
    <>
      <SEO title="PodScribe.IO | Library" />

      <div className="flex-1 flex h-full overflow-hidden">
        {/* Primary column */}
        {LibraryData.episodes.length === 0 ? (
          <EmptyInboxMessage />
        ) : (
          selectedEpisodeState.episode && (
            <InboxMessage
              episode={selectedEpisodeState.episode}
              data={selectedEpisodeEditorData}
              selectedEpisodeState={selectedEpisodeState}
              SelectEpisodeHandler={SelectEpisodeHandler}
              RemoveEpisodeHandler={RemoveEpisodeHandler}
              handleOpenSearchModal={handleOpenSearchModal}
            />
          )
        )}

        <Inbox
          episodes={LibraryData.episodes || []}
          SelectEpisodeHandler={SelectEpisodeHandler}
          selectedEpisodeState={selectedEpisodeState}
          isEmpty={LibraryData.episodes.length === 0}
          onFilteredListChange={(filteredList) => {
            // Store filtered list in state for navigation
            setFilteredEpisodeList(filteredList);
          }}
        />
      </div>
      <Search
        openSearchModal={openSearchModal}
        setOpenSearchModal={setOpenSearchModal}
        SelectEpisodeHandler={SelectEpisodeHandler}
      />
      <ActionModalDanger
        show={isDeleteModalOpen}
        title="Delete Episode"
        BodyText={`Are you sure you want to delete "${episodeToDelete?.title}"? This action cannot be undone.`}
        CloseButtonText="Cancel"
        ActionButtonText="Delete"
        HandleOnClose={() => {
          setIsDeleteModalOpen(false);
          setEpisodeToDelete(null);
        }}
        HandleOnAction={handleConfirmDelete}
      />
    </>
  );
}
