import React from "react";
import { EmojiSadIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { BeatLoader } from "react-spinners";
import { Helmet } from "react-helmet";
import Button from "../../UI/Button";
import { axiosPrivate } from "../../common/axiosPrivate";
import toast from "react-hot-toast";
import SEO from "../SEO";

export default function CancelSubscription() {
  const { auth, refreshUserData } = useAuth();
  const [reason, setReason] = useState("");
  const [cancelledSuccessfully, setCancelledSuccessfully] = useState(false);

  const handleCancelSubscription = async () => {
    try {
      const res = await axiosPrivate.post("/payment/create-checkout-session/cancel", {
        reason: reason,
        effective_from: "next_billing_period",
      });
      if (res.data) {
        console.log("Subscription canceled");
        refreshUserData();
        setCancelledSuccessfully(true);
        toast.success("Subscription successfully canceled", {
          duration: 5000,
          position: "top-right",
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to cancel subscription. Please contact us at info@podscribe.io", {
        duration: 5000,
        position: "top-right",
      });
    }
  };

  return (
    <>
      <SEO title="PodScribe.IO | Cancel Subscription" />
      <div className="flex-1 flex flex-col overflow-y-auto pb-10">
        <div className=" bg-white px-4 py-5 border-b border-neutral-200 sm:px-6 m-3">
          {/* Page Header */}
          <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-lg leading-6 font-medium text-primary-600">Cancel Subscription</h3>
            </div>
          </div>
        </div>

        <div className="bg-white">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-4xl text-center">
              <EmojiSadIcon className="h-24 w-24 text-support-red-400 mx-auto" />
              <p className="mt-2 text-4xl font-bold tracking-tight text-neutral-900 sm:text-5xl">
                We are sorry to see you go!
              </p>
            </div>
            <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-neutral-600">
              Your subscription will remain active until the end of the current billing period. Please let us know why
              you are canceling your subscription.
            </p>
            <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-neutral-600">
              If you have any questions, please contact us at{" "}
              <a href="mailto:info@podscribe.io" className="text-primary-600">
                info@podscribe.io
              </a>
            </p>
            <div className="relative mt-8 flex justify-center">
              <textarea
                className="w-64 lg:w-6/12 h-24 p-3 border border-neutral-300 rounded-lg"
                placeholder="Reason for canceling subscription"
                value={reason}
                onChange={(e) => setReason(e.target.value)}></textarea>
            </div>

            <div className="mt-8 pb-8 flex justify-center">
              <Button onClick={handleCancelSubscription} className=" bg-support-red-600 hover:bg-support-red-800">
                Cancel Subscription
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
