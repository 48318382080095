import React from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import PodcastIcon from "../UI/PodcastIcon";
import { Gradient } from "../UI/gradient";
import { useEffect } from "react";
import {
  XIcon,
  MenuIcon,
  ChatIcon,
  UserIcon,
  LogoutIcon,
  CreditCardIcon,
  LightBulbIcon,
  CollectionIcon,
  HeartIcon,
  FolderIcon,
} from "@heroicons/react/outline";
import useUIState from "../hooks/useUIState";
import FeedbackModal from "../UI/feedbackModal";
import { useLocation } from "react-router-dom";

const AppShell = (props) => {
  const { auth, setAuth } = useAuth();
  const { UIState, setUIState } = useUIState();
  const handleFeedbackModalOpen = () => {
    setUIState({
      ...UIState,
      feedbackModalOpen: !UIState.feedbackModalOpen,
    });
    // console.log("UIState", UIState);
    console.log("Feedback Modal Opened");
  };

  let location = useLocation();
  console.log("Location: ", location.pathname);
  let navigate = useNavigate();

  const logout = () => {
    setAuth({});
    localStorage.removeItem("session");
    navigate("/", { replace: true });
  };
  const navigation = [
    {
      name: "Inbox",
      id: "inbox",
      href: "/library",
      icon: LightBulbIcon,
      current: true,
    },
  ];
  const userNavigation = [
    { name: "Your Profile", href: "/profile", icon: UserIcon, isModal: false },
    {
      name: "Subscription",
      href: "/subscription",
      id: "subscription",
      icon: CreditCardIcon,
      isModal: false,
    },
    {
      name: "Feedback",
      id: "feedback",
      href: "/feedback",
      icon: ChatIcon,
      isModal: true,
      onClick: handleFeedbackModalOpen,
    },
    { name: "Sign out", href: "/logout", icon: LogoutIcon, isModal: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    document.documentElement.classList.add("overflow-hidden");
    document.body.classList.add("overflow-hidden");

    return () => {
      document.documentElement.classList.remove("overflow-hidden");
      document.body.classList.remove("overflow-hidden");
    };
  });

  return (
    <>
      <div className="bg-gradient-to-tr from-primary-600 to-secondary-600 h-full">
        <div className="flex">
          <Transition.Root show={mobileMenuOpen} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setMobileMenuOpen}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-neutral-600 bg-opacity-75" />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full">
                <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="absolute top-0 right-0 -mr-12 pt-4">
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setMobileMenuOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="pt-5 pb-4">
                    <div className="flex-shrink-0 flex items-center px-4">
                      {/* Logo Place */}
                      <Link to="/" aria-label="Home">
                        <PodcastIcon className="h-8 w-8 fill-primary-800 hover:scale-105 duration-200" />
                      </Link>
                    </div>
                    <nav aria-label="Sidebar" className="mt-5">
                      <div className="px-2 space-y-1">
                        {navigation.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            onClick={() => setMobileMenuOpen(false)}
                            className={`group p-2 rounded-md flex items-center text-base font-medium text-neutral-600 hover:bg-neutral-50 hover:text-neutral-900 ${
                              location.pathname.startsWith(item.href) ? "bg-neutral-100" : ""
                            }`}>
                            <item.icon
                              className="mr-4 h-6 w-6 text-neutral-400 group-hover:text-neutral-500"
                              aria-hidden="true"
                            />
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    </nav>
                  </div>
                  <div className="pt-4 pb-3 border-t border-neutral-200" />
                  <div className="px-2 space-y-1">
                    {userNavigation.map((item) =>
                      item.isModal ? (
                        <button
                          key={item.name}
                          onClick={() => {
                            item.onClick();
                            setMobileMenuOpen(false);
                          }}
                          className={`group p-2 rounded-md flex items-center text-base font-medium text-neutral-600
                        hover:bg-neutral-50 hover:text-neutral-800 ${
                          location.pathname.startsWith(item.href) ? "bg-neutral-100" : ""
                        }`}>
                          <item.icon
                            className="mr-4 h-6 w-6 text-neutral-400 group-hover:text-neutral-500"
                            aria-hidden="true"
                          />
                          {item.name}
                        </button>
                      ) : (
                        <Link
                          key={item.name}
                          to={item.href}
                          onClick={() => setMobileMenuOpen(false)}
                          className={`group p-2 rounded-md flex items-center text-base font-medium text-neutral-600
                        hover:bg-neutral-50 hover:text-neutral-800 ${
                          location.pathname.startsWith(item.href) ? "bg-neutral-100" : ""
                        }`}>
                          <item.icon
                            className="mr-4 h-6 w-6 text-neutral-400 group-hover:text-neutral-500"
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      )
                    )}
                  </div>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </Dialog>
          </Transition.Root>

          {/* Static sidebar for desktop */}
          <div className="hidden lg:flex lg:flex-shrink-0 mt-3">
            <div className="flex flex-col w-20">
              <div className="flex-1 flex flex-col min-h-0 overflow-y-auto bg-primary-600 rounded-r-lg">
                <div className="flex-1">
                  <div className="bg-primary-700 py-4 flex items-center justify-center">
                    {/* Logo Place */}
                    <Link to="/" aria-label="Home">
                      <PodcastIcon className="h-8 w-8 fill-primary-200 hover:scale-105 duration-200" />
                    </Link>
                  </div>
                  <nav aria-label="Sidebar" className="py-6 flex flex-col items-center space-y-3">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={`flex items-center p-4 rounded-lg text-primary-200 hover:bg-primary-700 ${
                          location.pathname.startsWith(item.href) ? "bg-primary-800" : ""
                        }`}>
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                        <span className="sr-only">{item.name}</span>
                      </Link>
                    ))}
                  </nav>
                </div>
                <div className="py-3 flex flex-col items-center space-y-3 bg-primary-800">
                  {userNavigation.map((item) =>
                    item.isModal ? (
                      <button
                        key={item.name}
                        onClick={() => {
                          item.onClick();
                          setMobileMenuOpen(false);
                        }}
                        className={`flex items-center p-1 rounded-lg text-primary-200 hover:bg-primary-700 ${
                          location.pathname.startsWith(item.href) ? "bg-primary-900" : ""
                        }`}>
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                        <span className="sr-only">{item.name}</span>
                      </button>
                    ) : (
                      <Link
                        key={item.name}
                        to={item.href}
                        onClick={() => setMobileMenuOpen(false)}
                        className={`flex items-center p-1 rounded-lg text-primary-200 hover:bg-primary-700 ${
                          location.pathname.startsWith(item.href) ? "bg-primary-900" : ""
                        }`}>
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                        <span className="sr-only">{item.name}</span>
                      </Link>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex-1 min-w-0 flex flex-col h-dvh overflow-hidden">
            {/* Mobile top navigation */}
            <div className="lg:hidden h-16">
              <div className="bg-primary-600 py-2 px-4 flex items-center justify-between sm:px-6 lg:px-8 rounded-b-2xl">
                <Link to="/" aria-label="Home">
                  <PodcastIcon className="h-8 w-8 fill-white hover:scale-105 duration-200" />
                </Link>

                <div>
                  <h1 className="font-medium text-lg text-white">Podscribe.IO</h1>
                </div>
                <div>
                  <button
                    type="button"
                    className="-mr-3 h-12 w-12 inline-flex items-center justify-center bg-primary-600 rounded-md text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setMobileMenuOpen(true)}>
                    <span className="sr-only">Open sidebar</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>

            <FeedbackModal
              show={UIState.feedbackModalOpen}
              CloseButtonText="Cancel"
              HandleOnAction={handleFeedbackModalOpen}
              HandleOnClose={handleFeedbackModalOpen}
            />
            <main className="min-w-0 flex-1 flex flex-col h-full lg:order-last overflow-hidden">
              {/* Primary column */}
              <div id="banner"></div>
              {props.children}
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppShell;
