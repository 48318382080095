import React, { useState, useRef } from "react";
import PodcastSearch from "./PodcastSearch";
import PodcastEpisodes from "./PodcastEpisodes";
import { axiosPrivate } from "../../../common/axiosPrivate";

export default function SearchPodcastTab({ onClose, isLimitReached }) {
  const [selectedPodcast, setSelectedPodcast] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const handleFavoriteOnClick = async (feedId, isFavorite) => {
    try {
      await axiosPrivate.post(`/podcast/favorites/${feedId}`, {
        is_favorite: isFavorite,
      });
    } catch (error) {
      console.error("Error updating favorite status: ", error);
    }
  };

  const episodesContainerRef = useRef(null);

  const handleSelectPodcast = (podcast) => {
    setSelectedPodcast(podcast);
  };

  const handleBack = () => {
    setSelectedPodcast(null);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  return selectedPodcast ? (
    <PodcastEpisodes
      podcast={selectedPodcast}
      onBack={handleBack}
      handleFavoriteOnClick={handleFavoriteOnClick}
      onClose={onClose}
      episodesContainerRef={episodesContainerRef}
    />
  ) : (
    <PodcastSearch
      searchValue={searchValue}
      onSearchChange={handleSearch}
      onSelectPodcast={handleSelectPodcast}
      handleFavoriteOnClick={handleFavoriteOnClick}
      isLimitReached={isLimitReached}
    />
  );
}
