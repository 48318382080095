import { Fragment, useState } from "react";
import useLibraryData from "../../hooks/useLibraryData";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import Img from "../../UI/Img";
import { ExclamationCircleIcon, XIcon, SearchIcon } from "@heroicons/react/outline";
import { ArrowRightIcon } from "@heroicons/react/solid";
import { remove_html_tags } from "../../common/utils";
import { Highlight, HighlightWithoutContext, HighlightWithoutContextAndReturnOnNoMatch } from "../../common/utils";
import { useDebounceValue } from "usehooks-ts";

function getMatchContext(text, searchTerm, wordCount = 10) {
  if (!text || !searchTerm) return "";
  
  const lowerText = text.toLowerCase();
  const lowerTerm = searchTerm.toLowerCase();
  const matchIndex = lowerText.indexOf(lowerTerm);

  if (matchIndex === -1) return "";

  // Find the start of the sentence (look for period + space before match)
  let startIndex = text.lastIndexOf(". ", matchIndex);
  startIndex = startIndex === -1 ? 0 : startIndex + 2;

  // Find the end of the sentence (look for period + space after match)
  let endIndex = text.indexOf(". ", matchIndex);
  endIndex = endIndex === -1 ? text.length : endIndex;

  // Get the full sentence
  const sentence = text.slice(startIndex, endIndex);

  // Split into words and get context
  const words = sentence.split(" ");
  const matchWordIndex = words.findIndex((word) => word.toLowerCase().includes(lowerTerm));

  const start = Math.max(0, matchWordIndex - wordCount);
  const end = Math.min(words.length, matchWordIndex + wordCount + 1);

  return (start > 0 ? "... " : "") + words.slice(start, end).join(" ") + (end < words.length ? " ..." : "");
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Search({ openSearchModal, setOpenSearchModal, SelectEpisodeHandler }) {
  const [query, setQuery] = useState("");
  const { LibraryData, SetLibraryData } = useLibraryData();

  const episode_list = LibraryData.episodes;
  const searchTerm = query;

  function includesIgnoreCase(str, searchTerm) {
    return str ? str.toLowerCase().includes(searchTerm.toLowerCase()) : false;
  }

  function searchEpisodes(episode_list, searchTerm) {
    if (searchTerm === "") {
      return [];
    }

    return episode_list.filter((episode) => {
      // Add match locations to each episode with null checks
      episode.matchLocations = {
        title: episode.title && includesIgnoreCase(episode.title, searchTerm),
        podcastTitle: episode.podcast_title && includesIgnoreCase(episode.podcast_title, searchTerm),
        description: episode.description && includesIgnoreCase(episode.description, searchTerm),
        summary: episode.is_summarized && 
                 episode.summary?.episode_summary?.summary && 
                 includesIgnoreCase(episode.summary.episode_summary.summary, searchTerm),
        transcript: episode.is_transcribed && 
                   episode.transcription?.text && 
                   includesIgnoreCase(episode.transcription.text, searchTerm),
      };

      // Return true if any location matches
      return Object.values(episode.matchLocations).some((match) => match);
    });
  }

  const filteredItems = searchEpisodes(episode_list, searchTerm);

  return (
    <Transition.Root show={openSearchModal} as={Fragment} afterLeave={() => setQuery("")} appear>
      <Dialog as="div" className="relative z-10" onClose={setOpenSearchModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox
                onChange={(item) => {
                  SelectEpisodeHandler(item.id);
                  setOpenSearchModal(false);
                }}>
                <div className="relative border-b border-neutral-200">
                  <SearchIcon
                    className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-neutral-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-10 text-neutral-900 placeholder:text-neutral-400 focus:ring-0 focus:outline-none text-base"
                    placeholder="Search episodes..."
                    onChange={(event) => setQuery(event.target.value)}
                  />
                  {query && (
                    <button className="absolute inset-y-0 right-2 flex items-center" onClick={() => setQuery("")}>
                      <XIcon className="h-5 w-5 text-neutral-400 hover:text-neutral-600" />
                    </button>
                  )}
                </div>
                {filteredItems.length > 0 && (
                  <Combobox.Options static className="max-h-96 scroll-py-3 overflow-y-auto p-3">
                    {filteredItems.map((item) => (
                      <Combobox.Option
                        key={item.id}
                        value={item}
                        className={({ active }) =>
                          classNames("flex cursor-default select-none rounded-lg p-3", active && "bg-neutral-50")
                        }>
                        {({ active }) => (
                          <div className="flex items-start space-x-4 w-full">
                            <div className="flex-shrink-0">
                              <Img src={item.image} className="h-12 w-12 rounded-md object-cover" aria-hidden="true" />
                            </div>

                            <div className="flex-1 min-w-0 space-y-2">
                              {/* Always show podcast title */}
                              <p className="text-xs text-primary-600">
                                {item.matchLocations.podcastTitle ? (
                                  <HighlightWithoutContext
                                    text={item.podcast_title || 'Untitled Podcast'}
                                    highlight={searchTerm}
                                    highlightClassName="bg-primary-50 text-primary-700 rounded px-0.5"
                                  />
                                ) : (
                                  item.podcast_title || 'Untitled Podcast'
                                )}
                              </p>

                              {/* Always show episode title */}
                              <p className="text-sm font-medium text-neutral-900">
                                {item.matchLocations.title ? (
                                  <HighlightWithoutContext
                                    text={item.title || 'Untitled Episode'}
                                    highlight={searchTerm}
                                    highlightClassName="bg-primary-100 text-primary-900 rounded px-0.5"
                                  />
                                ) : (
                                  item.title || 'Untitled Episode'
                                )}
                              </p>

                              {/* Show matches with context */}
                              <div className="space-y-2">
                                {/* Show description match context */}
                                {item.matchLocations.description && item.description && (
                                  <div className="text-sm">
                                    <p className="text-xs font-medium text-neutral-500 mb-1">Description:</p>
                                    <p className="text-neutral-700">
                                      <HighlightWithoutContext
                                        text={getMatchContext(remove_html_tags(item.description), searchTerm)}
                                        highlight={searchTerm}
                                        highlightClassName="bg-primary-50 text-primary-700 rounded px-0.5"
                                      />
                                    </p>
                                  </div>
                                )}

                                {/* Show summary match context */}
                                {item.matchLocations.summary && item.summary?.episode_summary?.summary && (
                                  <div className="text-sm">
                                    <p className="text-xs font-medium text-neutral-500 mb-1">Summary:</p>
                                    <p className="text-neutral-700">
                                      <HighlightWithoutContext
                                        text={getMatchContext(item.summary.episode_summary.summary, searchTerm)}
                                        highlight={searchTerm}
                                        highlightClassName="bg-primary-50 text-primary-700 rounded px-0.5"
                                      />
                                    </p>
                                  </div>
                                )}

                                {/* Show transcript match context */}
                                {item.matchLocations.transcript && item.transcription?.text && (
                                  <div className="text-sm">
                                    <p className="text-xs font-medium text-neutral-500 mb-1">Transcript:</p>
                                    <p className="text-neutral-700">
                                      <HighlightWithoutContext
                                        text={getMatchContext(item.transcription.text, searchTerm)}
                                        highlight={searchTerm}
                                        highlightClassName="bg-primary-50 text-primary-700 rounded px-0.5"
                                      />
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>

                            {active && (
                              <div className="flex-shrink-0 text-primary-600">
                                <ArrowRightIcon className="h-5 w-5" />
                              </div>
                            )}
                          </div>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}
                {query !== "" && filteredItems.length === 0 && (
                  <div className="px-6 py-12 text-center">
                    <div className="rounded-full bg-neutral-100 p-3 w-12 h-12 mx-auto mb-4 flex items-center justify-center">
                      <SearchIcon className="h-6 w-6 text-neutral-400" />
                    </div>
                    <p className="text-sm font-medium text-neutral-900 mb-1">No results found</p>
                    <p className="text-sm text-neutral-500">Try adjusting your search terms</p>
                  </div>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
