import { useState } from "react";
import { ChevronUpIcon, ChevronDownIcon, PlayIcon } from "@heroicons/react/outline";
import InfoMsgAction from "../../UI/InfoMsgAction";

const formatTime = (seconds) => {
  const h = Math.floor(seconds / 3600)
    .toString()
    .padStart(2, "0");
  const m = Math.floor((seconds % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const s = Math.floor(seconds % 60)
    .toString()
    .padStart(2, "0");
  return `${h}:${m}:${s}`;
};

export const Summary = (props) => {
  const [openIndex, setOpenIndex] = useState(null);

  return (
    <div className="flex flex-col items-center justify-between w-full max-w-4xl mx-auto px-4 py-6">
      {props.selectedEpisodeState.episode.summary?.chapters ? (
        <div className="w-full space-y-8 w-full">
          {/* Introduction section */}
          <section className="bg-white rounded-lg p-6 w-full">
            <h3 className="text-xl font-semibold text-primary-800 mb-4">Introduction</h3>
            <div className="prose prose-sm text-neutral-700 w-full">
              <p className="leading-relaxed w-full">
                {props.selectedEpisodeState.episode.summary?.episode_summary?.summary}
              </p>
            </div>
          </section>
          {/* Quick Takeaways section */}
          <section className="bg-white rounded-lg p-6">
            <h3 className="text-xl font-semibold text-primary-800 mb-4">Quick Takeaways</h3>
            <ul className="space-y-4">
              {props.selectedEpisodeState.episode.summary.chapters.map((chapter, index) => (
                <li key={index} className="flex items-start">
                  <span className="text-neutral-400 mr-2">•</span>
                  <div className="flex-1">
                    <p className="text-sm text-neutral-700 leading-relaxed">{chapter.combined_key_takeaway}</p>
                  </div>
                </li>
              ))}
            </ul>
          </section>

          {/* Deepdive section */}
          <section className="bg-white rounded-lg p-6">
            <h3 className="text-xl font-semibold text-primary-800 mb-6">Deepdive</h3>
            <div className="space-y-6">
              {props.selectedEpisodeState.episode.summary.chapters.map((chapter, index) => (
                <div key={index} className="border-b border-neutral-200 last:border-0 pb-6 last:pb-0">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                      <button
                        onClick={() => props.SelectSegmentHandler(chapter.start_time)}
                        className="p-2 rounded-full hover:bg-neutral-100 transition-colors"
                        title="Play this segment">
                        <PlayIcon className="h-6 w-6 text-primary-600" aria-hidden="true" />
                      </button>
                      <div>
                        <h4 className="text-lg font-medium text-primary-700">{chapter.title}</h4>
                        {chapter.start_time !== undefined && chapter.end_time !== undefined && (
                          <p className="text-sm text-neutral-500">
                            {formatTime(Number(chapter.start_time.toFixed(2)))} -{" "}
                            {formatTime(Number(chapter.end_time.toFixed(2)))}
                          </p>
                        )}
                      </div>
                    </div>
                    <button
                      onClick={() => setOpenIndex(openIndex === index ? null : index)}
                      className="p-2 rounded-md hover:bg-neutral-100 transition-colors">
                      {openIndex === index ? (
                        <ChevronUpIcon className="h-5 w-5 text-neutral-600" aria-hidden="true" />
                      ) : (
                        <ChevronDownIcon className="h-5 w-5 text-neutral-600" aria-hidden="true" />
                      )}
                    </button>
                  </div>

                  {openIndex === index && (
                    <div className="mt-4 space-y-4">
                      <div className="prose prose-sm text-neutral-700">
                        <p className="leading-relaxed">{chapter.combined_summary}</p>
                      </div>

                      {chapter.references && (
                        <div className="mt-4">
                          <h5 className="text-sm font-medium text-secondary-600 mb-2">References</h5>
                          <ul className="list-disc pl-5 space-y-1">
                            {chapter.references.map((reference, refIndex) => (
                              <li key={refIndex} className="text-sm text-neutral-600">
                                {reference}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}

                      <div className="mt-4">
                        <h5 className="text-sm font-medium text-secondary-600 mb-2">Notes</h5>
                        <ul className="space-y-2">
                          {chapter.combined_main_points.map((point, pointIndex) => (
                            <li key={pointIndex} className="flex items-start">
                              <span className="text-neutral-400 mr-2">•</span>
                              <span className="text-sm text-neutral-700">{point}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </section>
        </div>
      ) : (
        <div className="w-full">
          <InfoMsgAction
            msg="Podcast summary is not available"
            action="Transcribe"
            onClick={props.HandleTranscribeEpisode}
          />
        </div>
      )}
    </div>
  );
};

export default Summary;
