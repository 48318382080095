import { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon, GiftIcon } from "@heroicons/react/solid";
import { Badge } from "../../UI/Badge";
import { axiosPrivate } from "../../common/axiosPrivate";
import useAuth from "../../hooks/useAuth";
import toast from "react-hot-toast";
import { Banner } from "../../UI/Banner";
import { Switch } from "@headlessui/react";
import ActionModalDanger from "../../UI/ActionModalDanger";
import ActionModalInfo from "../../UI/ActionModalInfo";
import { duration } from "moment";
import PricingTable from "./PricingTable";
import PricingNewSubscription from "./PricingNewSubscription";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import ManageSubscription from "./ManageSubscription";

export default function Pricing(props) {
  const { auth, refreshUserData } = useAuth();
  const { checkoutResult } = useParams();

  return (
    <>
      {checkoutResult === "success" && (
        <Banner strongText="Subscription successful">
          Your subscription has been successfully processed. You can now access all our features.
        </Banner>
      )}
      {auth.user.subscription_status === "active" && <ManageSubscription />}
      {auth.user.subscription_status !== "active" && (
        <>
          <PricingNewSubscription />
        </>
      )}
    </>
  );
}
