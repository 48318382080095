export default function CardPagination(props) {
  let CurrentPage = props.CurrentPage;
  let ItemsPerPage = props.ItemsPerPage;
  let Start = (CurrentPage - 1) * ItemsPerPage;
  let End = CurrentPage * ItemsPerPage;
  let TotalResults = props.TotalResults;
  return (
    <nav
      className="bg-white px-4 py-3 flex items-center justify-between border-t border-neutral-200 sm:px-6 mb-3"
      aria-label="Pagination">
      <div className="hidden sm:block">
        <p className="text-sm text-neutral-700">
          Showing <span className="font-medium">{Start}</span> to <span className="font-medium">{End}</span> of{" "}
          <span className="font-medium">{TotalResults}</span> results
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        <button
          onClick={props.onPrevious}
          className="relative inline-flex items-center px-4 py-2 border border-neutral-300 text-sm font-medium rounded-md text-neutral-700 bg-white hover:bg-neutral-50">
          Previous
        </button>
        <button
          onClick={props.onNext}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-neutral-300 text-sm font-medium rounded-md text-neutral-700 bg-white hover:bg-neutral-50">
          Next
        </button>
      </div>
    </nav>
  );
}
