import classNames from "classnames";
import { useState } from "react";
import { Button } from "./Button";
import { Container } from "./Container";
import { ClockIcon } from "@heroicons/react/outline";
import { SparklesIcon } from "@heroicons/react/outline";
import { Switch } from "@headlessui/react";
import { EnterprisePlan } from "./EnterprisePlan";

function SwirlyDoodle({ className }) {
  return (
    <svg aria-hidden="true" viewBox="0 0 281 40" className={className} preserveAspectRatio="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M240.172 22.994c-8.007 1.246-15.477 2.23-31.26 4.114-18.506 2.21-26.323 2.977-34.487 3.386-2.971.149-3.727.324-6.566 1.523-15.124 6.388-43.775 9.404-69.425 7.31-26.207-2.14-50.986-7.103-78-15.624C10.912 20.7.988 16.143.734 14.657c-.066-.381.043-.344 1.324.456 10.423 6.506 49.649 16.322 77.8 19.468 23.708 2.65 38.249 2.95 55.821 1.156 9.407-.962 24.451-3.773 25.101-4.692.074-.104.053-.155-.058-.135-1.062.195-13.863-.271-18.848-.687-16.681-1.389-28.722-4.345-38.142-9.364-15.294-8.15-7.298-19.232 14.802-20.514 16.095-.934 32.793 1.517 47.423 6.96 13.524 5.033 17.942 12.326 11.463 18.922l-.859.874.697-.006c2.681-.026 15.304-1.302 29.208-2.953 25.845-3.07 35.659-4.519 54.027-7.978 9.863-1.858 11.021-2.048 13.055-2.145a61.901 61.901 0 0 0 4.506-.417c1.891-.259 2.151-.267 1.543-.047-.402.145-2.33.913-4.285 1.707-4.635 1.882-5.202 2.07-8.736 2.903-3.414.805-19.773 3.797-26.404 4.829Zm40.321-9.93c.1-.066.231-.085.29-.041.059.043-.024.096-.183.119-.177.024-.219-.007-.107-.079ZM172.299 26.22c9.364-6.058 5.161-12.039-12.304-17.51-11.656-3.653-23.145-5.47-35.243-5.576-22.552-.198-33.577 7.462-21.321 14.814 12.012 7.205 32.994 10.557 61.531 9.831 4.563-.116 5.372-.288 7.337-1.559Z"
      />
    </svg>
  );
}

function CheckIcon({ className }) {
  return (
    <svg aria-hidden="true" className={classNames("h-6 w-6 flex-none fill-current stroke-current", className)}>
      <path
        d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
        strokeWidth={0}
      />
      <circle cx={12} cy={12} r={8.25} fill="none" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

function MySparklesIcon({ className }) {
  return <SparklesIcon className={classNames("h-6 w-6 flex-none stroke-current", className)} />;
}

function Plan({ name, price, description, href, features, featured = false, coming_features = [], frequency }) {
  return (
    <section
      className={classNames(
        "flex flex-col rounded-3xl px-6 sm:px-8 shadow-md",
        featured
          ? "order-first bg-primary-600 py-8 lg:order-none shadow-primary-900"
          : "py-8 lg:py-8 shadow-primary-900"
      )}>
      <h3 className="mt-5 font-display text-2xl text-white">{name}</h3>
      <p className={classNames("mt-2 text-base", featured ? "text-white" : "text-slate-400")}>{description}</p>
      <div className="">
        <p className="mt-5 flex items-baseline gap-x-1">
          <span className="order-first font-display text-5xl font-extrabold tracking-tight text-white">
            {price[frequency.value]}
          </span>
          <span className="text-sm font-semibold leading-6 text-white">{frequency.priceSuffix}</span>
        </p>
        <p className="text-sm font-semibold leading-6 text-white">plus local taxes</p>
        {frequency.value === "yearly" ? <p className="text-sm font-bold text-secondary-400">Save 2 months!</p> : null}
      </div>
      <ul
        className={classNames(
          "order-last mt-10 flex flex-col gap-y-3 text-sm",
          featured ? "text-white" : "text-slate-200"
        )}>
        {features.map((feature) => (
          <li key={feature} className="flex">
            <CheckIcon className={featured ? "text-white" : "text-slate-400"} />
            <span className="ml-4">{feature}</span>
          </li>
        ))}
        {coming_features.map((feature) => (
          <li key={feature} className="flex">
            <MySparklesIcon className={featured ? " text-secondary-200" : "text-secondary-200"} title="Coming soon!" />
            <span className="ml-4">{feature}</span>
          </li>
        ))}
      </ul>
      <Button
        href={href}
        variant={featured ? "solid" : "outline"}
        color="white"
        className="mt-8"
        aria-label={`Get started with the ${name} plan for ${price}`}>
        Get started
      </Button>
    </section>
  );
}

export function Pricing() {
  const [enableAnnualBilling, setEnableAnnualBilling] = useState(false);
  return (
    <section id="pricing" aria-label="Pricing" className="bg-primary-800 py-20 sm:py-32">
      <Container>
        <div className="md:text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
            <span className="relative whitespace-nowrap">
              <SwirlyDoodle className="absolute left-0 top-1/2 h-[1em] w-full fill-secondary-400" />
              <span className="relative">Simple pricing,</span>
            </span>{" "}
            for everyone.
          </h2>
          <p className="mt-4 text-lg text-slate-200">Choose the Package That Fits You Best!</p>
          <p className="mt-4 text-lg text-slate-400">
            Podscribe.IO transforms audio into structured, searchable knowledge data, allowing users to easily access
            and digest podcast (and other audio ) content for maximum educational and inspirational benefit.
          </p>
        </div>
        <div className="mt-16 flex justify-center">
          <Switch.Group as="div" className="flex items-center">
            <Switch
              disabled={false}
              checked={enableAnnualBilling}
              onChange={() => setEnableAnnualBilling(!enableAnnualBilling)}
              className={classNames(
                enableAnnualBilling ? "bg-primary-400" : "bg-secondary",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
              )}>
              <span
                aria-hidden="true"
                className={classNames(
                  enableAnnualBilling ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
            <Switch.Label as="span" className="ml-3 text-sm">
              <span className="font-medium text-slate-400">Annual billing</span>{" "}
              <span className="text-white">(Save 2 months!)</span>
            </Switch.Label>
          </Switch.Group>
        </div>
        <div className="-mx-4 mt-16 grid max-w-2xl grid-cols-1 gap-y-10 sm:mx-auto lg:-mx-8 lg:max-w-none lg:grid-cols-3 xl:mx-0 xl:gap-x-8">
          <Plan
            name="Free"
            price={{ monthly: "$0", yearly: "$0" }}
            frequency={{
              value: enableAnnualBilling ? "yearly" : "monthly",
              priceSuffix: "",
            }}
            description="To test our features"
            href="/register"
            features={[
              "Starting 100 minutes of AI processing for new audio",
              "Support for 30+ languages (Beta)",
              "Unlimited access to existing content",
              "Discover and add new podcasts and episodes",
              "Bookmark favorite episodes",
              "Export notes to Markdown",
              "Inbox holds up to 10 items",
            ]}
          />
          <Plan
            name="Standard"
            price={{ monthly: "$5", yearly: "$50" }}
            frequency={{
              value: enableAnnualBilling ? "yearly" : "monthly",
              priceSuffix: enableAnnualBilling ? "/year" : "/month",
            }}
            description="Ideal for serious podcast enthusiasts"
            href="/register"
            features={[
              "Everything in Free, plus:",
              "500 minutes of AI processing for new audio each month",
              "Unlimited inbox storage",
              "Buy extra minutes: $10 per 1000 minutes",
              "Upload or link custom audio files",
              "1GB storage for custom audio files",
              "Import your Youtube videos (beta)",
              "Share AI processed notes via Link",
            ]}
            coming_features={["Notion, Readwise, and other integrations"]}
          />
          <Plan
            featured
            name="Premium"
            price={{ monthly: "$12", yearly: "$120" }}
            frequency={{
              value: enableAnnualBilling ? "yearly" : "monthly",
              priceSuffix: enableAnnualBilling ? "/year" : "/month",
            }}
            description="Designed for professionals, researchers"
            href="/register"
            features={[
              "Everything in Standard, plus:",
              "1200 minutes of AI processing for new audio each month",
              "Buy extra minutes: $10 per 1000 minutes",
              "Upload or link custom audio files",
              "5GB storage for custom audio files",
              "Import your Youtube videos (beta)",
              "Share AI processed notes via Link",
            ]}
            coming_features={["Chat with transcripts using Generative AI", "Notion, Readwise, and other integrations"]}
          />
        </div>
        <div className="mt-16">
          <EnterprisePlan />
        </div>
      </Container>
    </section>
  );
}
