import { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/solid";
import { Badge } from "../../UI/Badge";
import { axiosPrivate } from "../../common/axiosPrivate";
import useAuth from "../../hooks/useAuth";
import toast from "react-hot-toast";
import { Banner } from "../../UI/Banner";
import { Switch } from "@headlessui/react";
import ActionModalDanger from "../../UI/ActionModalDanger";
import ActionModalInfo from "../../UI/ActionModalInfo";
import { duration } from "moment";
import { SparklesIcon } from "@heroicons/react/outline";

export default function UpdatePricingTable(props) {
  const handleCheckoutButton = props.handleCheckoutButton;
  const currentTier = props.currentTier;
  const currentFrequency = props.currentFrequency;
  const [enableAnnualBilling, setEnableAnnualBilling] = useState(false);
  const disableButtons = props.disableButtons;

  const frequencies = [
    {
      value: "monthly",
      label: "Monthly",
      priceSuffix: "/month",
      checked: true,
      billing_cycle: "month",
    },
    {
      value: "yearly",
      label: "yearly",
      priceSuffix: "/year",
      checked: false,
      billing_cycle: "year",
    },
  ];
  const tiers = [
    {
      name: "Free",
      id: "free",
      href: "/register",
      price: { monthly: "$0", yearly: "$0" },
      description: "To test our features",
      features: [
        "Starting 100 minutes of AI processing for new audio",
        "Unlimited access to existing content",
        "Discover and add new podcasts and episodes",
        "Bookmark favorite episodes",
        "Export notes to Markdown",
        "Inbox holds up to 10 items",
      ],
      mostPopular: false,
    },
    {
      name: "Standard",
      id: "standard",
      href: "/register",
      price: { monthly: "$5", yearly: "$50" },
      description: "Ideal for serious podcast enthusiasts",
      features: [
        "Everything in Free, plus:",
        "500 minutes of AI processing for new audio each month",
        "Unlimited inbox storage",
        "Buy extra minutes: $10 per 1000 minutes",
        "Upload or link custom audio files",
        "1GB storage for custom audio files",
        "Import your Youtube videos (beta)",
        "Share AI processed notes via Link",
      ],
      coming_features: ["Notion, Readwise, and other integrations"],
      mostPopular: false,
    },
    {
      name: "Premium",
      id: "premium",
      href: "/register",
      price: { monthly: "$12", yearly: "$120" },
      description: "Designed for professionals, researchers",
      features: [
        "Everything in Standard, plus:",
        "1200 minutes of AI processing for new audio each month",
        "Buy extra minutes: $10 per 1000 minutes",
        "Upload or link custom audio files",
        "5GB storage for custom audio files",
        "Share AI processed notes via Link",
      ],
      coming_features: ["Chat with transcripts using Generative AI", "Notion, Readwise, and other integrations"],
      mostPopular: true,
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [frequency, setFrequency] = useState(frequencies.find((f) => f.checked));

  // on enableAnnualBilling change, update the frequency to yearly
  useEffect(() => {
    if (enableAnnualBilling) {
      setFrequency(frequencies.find((f) => f.value === "yearly"));
    } else {
      setFrequency(frequencies.find((f) => f.value === "monthly"));
    }
    console.log("enableAnnualBilling", enableAnnualBilling);
  }, [enableAnnualBilling]);

  return (
    <>
      <div className="mt-10 flex justify-center">
        <Switch.Group as="div" className="flex items-center">
          <Switch
            disabled={false}
            checked={enableAnnualBilling}
            onChange={() => setEnableAnnualBilling(!enableAnnualBilling)}
            className={classNames(
              enableAnnualBilling ? "bg-indigo-600" : "bg-gray-200",
              "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
            )}>
            <span
              aria-hidden="true"
              className={classNames(
                enableAnnualBilling ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              )}
            />
          </Switch>
          <Switch.Label as="span" className="ml-3 text-sm">
            <span className="font-medium text-neutral-900">Annual billing</span>{" "}
            <span className="text-secondary-600">(Save 2 months!)</span>
          </Switch.Label>
        </Switch.Group>
      </div>
      <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        {tiers.map((tier) => (
          <div
            key={tier.id}
            className={classNames(
              tier.mostPopular ? "ring-2 ring-secondary-600" : "ring-1 ring-neutral-200",
              "rounded-3xl p-8 xl:p-10"
            )}>
            <div className="flex items-center justify-between gap-x-4">
              <h3
                id={tier.id}
                className={classNames(
                  tier.mostPopular ? "text-secondary-600" : "text-neutral-900",
                  "text-lg font-semibold leading-8"
                )}>
                {tier.name}
              </h3>
              {currentTier == tier.id ? (
                <p className="rounded-full bg-primary-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-primary-600">
                  Current plan
                </p>
              ) : tier.mostPopular ? (
                <p className="rounded-full bg-secondary-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-secondary-600">
                  Most popular
                </p>
              ) : null}
            </div>
            <p className="mt-4 text-sm leading-6 text-neutral-600">{tier.description}</p>
            <p className="mt-6 flex items-baseline gap-x-1">
              <span className="text-4xl font-bold tracking-tight text-neutral-900">{tier.price[frequency.value]}</span>
              <span className="text-sm font-semibold leading-6 text-neutral-600">{frequency.priceSuffix}</span>
            </p>
            <p className="text-sm font-semibold leading-6 text-neutral-600">plus local taxes</p>
            {frequency.value == "yearly" ? (
              <p className="text-sm font-bold text-support-red-600">Save 2 months!</p>
            ) : null}
            <button
              disabled={
                tier.id === "free" ||
                disableButtons ||
                (currentTier == tier.id && currentFrequency == frequency.billing_cycle)
              }
              aria-describedby={tier.id}
              onClick={() => tier.id !== "free" && handleCheckoutButton(tier.id, frequency.value)}
              className={classNames(
                tier.id === "free" || (currentTier == tier.id && currentFrequency == frequency.billing_cycle)
                  ? "bg-neutral-300 text-white shadow-sm hover:bg-neutral-200 cursor-not-allowed"
                  : tier.mostPopular
                  ? "bg-secondary-600 text-white shadow-sm hover:bg-secondary-500"
                  : "text-primary-600 ring-1 ring-inset ring-primary-200 hover:ring-primary-300",
                "mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              )}>
              {tier.id === "free" ? "Free" : "Change plan"}
            </button>
            <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-neutral-600 xl:mt-10">
              {tier.features.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-primary-600" aria-hidden="true" />
                  {feature}
                </li>
              ))}
              {tier.coming_features &&
                tier.coming_features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <SparklesIcon className="h-6 w-5 flex-none stroke-current text-secondary-600" aria-hidden="true" />
                    <p>{feature}</p>
                  </li>
                ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="relative mt-10">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-primary-300" />
        </div>
      </div>
      <div className="relative flex justify-center pt-5">
        <span className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white px-3 text-base font-semibold leading-6 text-primary-600">
          Topup packages
        </span>
      </div>
      <div className="h-auto mt-5 rounded-3xl p-8 xl:p-10 border-2 ">
        <div className="flex items-center justify-between gap-x-4">
          <h3 id="topup" className="text-lg font-semibold leading-8 text-neutral-900">
            Topup-1000
          </h3>
        </div>
        <p className="mt-4 text-sm leading-6 text-neutral-600">
          This topup package provides an additional 1000 minutes of AI processing to your existing subscription. It can
          be carried over across billing cycles.
        </p>
        <p className="mt-6 flex items-baseline gap-x-1">
          <span className="text-4xl font-bold tracking-tight text-neutral-900">$10</span>
          <span className="text-sm font-semibold leading-6 text-neutral-600">USD</span>
        </p>
        <p className="text-sm font-semibold leading-6 text-neutral-600">plus local taxes</p>
        <button
          disabled={disableButtons}
          aria-describedby="topup-10"
          onClick={() => handleCheckoutButton("topup", "1000")}
          className="text-primary-600 ring-1 ring-inset ring-primary-200 hover:ring-primary-300 mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600">
          Buy Topup
        </button>
      </div>
    </>
  );
}
