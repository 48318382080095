import { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/solid";
import { Badge } from "../../UI/Badge";
import { axiosPrivate } from "../../common/axiosPrivate";
import useAuth from "../../hooks/useAuth";
import toast from "react-hot-toast";
import { Banner } from "../../UI/Banner";
import { Switch } from "@headlessui/react";
import ActionModalDanger from "../../UI/ActionModalDanger";
import ActionModalInfo from "../../UI/ActionModalInfo";
import { duration } from "moment";
import PricingTable from "./PricingTable";
import PaddleScript from "./PaddleScript";
import { Footer } from "../Landing/Footer";
import { Link } from "react-router-dom";

export default function PricingNewSubscription(props) {
  const { auth, refreshUserData } = useAuth();
  const [enableAnnualBilling, setEnableAnnualBilling] = useState(false);
  const [disableCheckoutButton, setDisableCheckoutButton] = useState(false);

  const handleCheckoutButton = async (tier, frequency) => {
    console.log("handleCheckoutButton", tier, frequency);
    // disable checkout button
    setDisableCheckoutButton(true);
    // create checkout session
    const label = tier + "_" + frequency;
    try {
      const res = await axiosPrivate.post("/payment/create-checkout-session/new", {
        tier: label, // e.g. standard_yearly
      });
      if (res.data) {
        if (res.data.action == "checkout") {
          // create the customer object based on the response
          const Paddle = window.Paddle;
          Paddle.Checkout.open({
            settings: {
              theme: "dark",
              locale: "en",
              allowLogout: false,
              successUrl: `${process.env.REACT_APP_BASE_URL}/subscription/success`,
            },
            items: [
              {
                priceId: res.data.price_id,
                quantity: 1,
              },
            ],
            customer: {
              id: res.data.customer_id,
              address: {
                id: res.data.address_id,
              },
              business: {
                id: res.data.business_id,
              },
            },

            customData: { user_id: auth.user.id },
          });
          // enable checkout button
          setDisableCheckoutButton(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="bg-white flex-1 flex flex-col overflow-y-auto pb-10">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <p className="mt-2 text-4xl font-bold tracking-tight sm:text-5xl text-primary-900 bg-gradient-to-r from-primary-600 to-primary-900 bg-clip-text text-transparent">
              Welcome to PodScribe.IO!
            </p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-secondary-600 font-semibold">
            Enhance your podcast journey like never before!
          </p>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg font-semibold leading-8 text-neutral-600">
            To unlock a world of limitless knowledge and premium features, explore our various subscription plans and
            consider upgrading your current subscription.
          </p>
          <p className="mx-auto mt-6 max-w-2xl text-center text-sm leading-6 text-neutral-600">
            As a <span className="font-bold">free user</span>, you start with{" "}
            <span className="font-bold">100 initial minutes</span> which you can use for processing episodes, and you
            have an <span className="font-bold">Inbox limit of 10</span>. However, to buy or get additional minutes, a
            subscription is required. You can also use{" "}
            <Link to="/repository" className="text-primary-800">
              existing episodes
            </Link>{" "}
            that have already been processed by other users.
          </p>

          {auth.user.subscription_status == "active" && <div className=""></div>}
          <PricingTable
            enableAnnualBilling={enableAnnualBilling}
            setEnableAnnualBilling={setEnableAnnualBilling}
            handleCheckoutButton={handleCheckoutButton}
            currentTier={auth.user.subscription_tier}
            disableButtons={disableCheckoutButton}
          />
        </div>
      </div>
      <PaddleScript />
    </>
  );
}
