export const calculateProcessingTime = (durationInSeconds) => {
  // Base processing time: 50x speed (1 minute processes 50 minutes of audio)
  const processingSeconds = Math.ceil(durationInSeconds / 50);
  
  // Add fixed 60 second buffer for API calls, model loading, etc
  const totalSeconds = processingSeconds + 60;
  
  return {
    estimatedSeconds: totalSeconds,
    displayTime: formatProcessingTime(Math.ceil(totalSeconds / 60)),
    startTime: new Date().getTime()
  };
};

export const formatProcessingTime = (seconds) => {
  if (seconds < 60) {
    return `${seconds}s`;
  }
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return remainingSeconds > 0 ? 
    `${minutes}m ${remainingSeconds}s` : 
    `${minutes}m`;
};

export const getRemainingTime = (startTime, estimatedSeconds) => {
  const elapsedMs = new Date().getTime() - startTime;
  const remainingSeconds = Math.max(0, estimatedSeconds - (elapsedMs / 1000));
  return {
    remainingSeconds: Math.ceil(remainingSeconds),
    display: formatProcessingTime(Math.ceil(remainingSeconds))
  };
};
