import React, { createContext, useState } from "react";

const UIContext = createContext();

export const UIProvider = ({ children }) => {
  const [UIState, setUIState] = useState({
    inboxSidebarOpen: false,
    feedbackModalOpen: false,
    inboxConfig: {
      sort: {
        key: "datePublished",
        order: "desc"
      },
      group: {
        isGrouped: false,
        groupKey: "podcast_title"
      }
    },
    episodeState: {
      processingTimes: {}, // { episodeId: { estimatedSeconds, startTime, display } }
      playerPositions: {},
    }
  });

  const updateEpisodeState = (episodeId, updates) => {
    setUIState(prev => ({
      ...prev,
      episodeState: {
        ...prev.episodeState,
        processingTimes: {
          ...prev.episodeState.processingTimes,
          ...(updates.processingTime !== undefined && { [episodeId]: updates.processingTime }),
        },
        playerPositions: {
          ...prev.episodeState.playerPositions,
          ...(updates.playerPosition !== undefined && { [episodeId]: updates.playerPosition }),
        }
      }
    }));
  };

  return (
    <UIContext.Provider value={{ UIState, setUIState, updateEpisodeState }}>
      {children}
    </UIContext.Provider>
  );
};

export default UIContext;
