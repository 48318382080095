import { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/solid";
import { Badge } from "../../UI/Badge";
import { axiosPrivate } from "../../common/axiosPrivate";
import useAuth from "../../hooks/useAuth";
import toast from "react-hot-toast";
import { Banner } from "../../UI/Banner";
import { Switch } from "@headlessui/react";
import ActionModalDanger from "../../UI/ActionModalDanger";
import ActionModalInfo from "../../UI/ActionModalInfo";
import { duration } from "moment";
import PaddleScript from "./PaddleScript";
import UpdatePricingTable from "./UpdatePricingTable";
import { useNavigate } from "react-router-dom";

export default function ManageSubscription(props) {
  const { auth, refreshUserData } = useAuth();
  const [enableAnnualBilling, setEnableAnnualBilling] = useState(false);
  const [disableCheckoutButton, setDisableCheckoutButton] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalBodyText, setModalBodyText] = useState("");
  const [modalTier, setModalTier] = useState("");
  const [modalFrequency, setModalFrequency] = useState("");
  const [updatedSubscription, setUpdatedSubscription] = useState({});
  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();

  const handleOnClose = () => {
    setShowModal(false);
  };

  const handleModalActionButton = async () => {
    const label = modalTier + "_" + modalFrequency;
    console.log("handleOnActionButton", label);
    try {
      const res = await axiosPrivate.post("/payment/create-checkout-session/update", {
        tier: label, // e.g. standard_yearly
      });
      if (res.data) {
        console.log("Subscription Updated:", res.data?.subscription);
        setUpdatedSubscription(res.data?.subscription);
        navigate("/subscription/update/success", {
          state: {
            old_tier: auth.user.subscription_tier,
            old_frequency: auth.user.subscription_frequency,
            old_topup_credits: auth.user.podscribe_topup_minutes,
            new_tier: modalTier,
            new_frequency: modalFrequency,
            new_topup_credits: auth.user.podscribe_topup_minutes + 1000,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
    // close the modal
    setShowModal(false);
  };

  const handleCheckoutButton = async (tier, frequency) => {
    console.log("handleCheckoutButton", tier, frequency);
    // disable checkout button
    setDisableCheckoutButton(true);
    // create checkout session
    const label = tier + "_" + frequency;
    try {
      const res = await axiosPrivate.post("/payment/create-checkout-session/preview", {
        tier: label, // e.g. standard_yearly
      });
      if (res.data) {
        console.log("checkout session created", res.data);
        let action = res.data.update_summary.result.action;
        let amount = res.data.update_summary.result.amount / 100; // convert to USD
        let currency = res.data.update_summary.result.currency_code;
        setDisableCheckoutButton(false);
        setModalTier(tier);
        setModalFrequency(frequency);
        if (tier == "topup") {
          setModalBodyText(
            <>
              You are about to buy <strong>Topup-1000</strong>. We will {action} <strong>${amount}</strong>{" "}
              <strong>{currency}</strong>.
            </>
          );
        } else {
          setModalBodyText(
            <>
              You are about to change your subscription to <strong>{tier}</strong> tier with{" "}
              <strong>{frequency}</strong> billing. We will {action} <strong>${amount}</strong>{" "}
              <strong>{currency}</strong>.
            </>
          );
        }
        setShowModal(true);
      }
    } catch (error) {
      console.log(error);
      setDisableCheckoutButton(false);
    }
  };

  return (
    <>
      <div className="bg-white flex-1 flex flex-col overflow-y-auto pb-10 rounded-lg scrollbar-thin scrollbar-thumb-neutral-200 scrollbar-track-transparent hover:scrollbar-thumb-neutral-300">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <p className="mt-2 text-4xl font-bold tracking-tight text-neutral-900 sm:text-5xl">
              Upgrade your subscription
            </p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-neutral-600">
            Enhance your podcast journey like never before! Explore our various subscription plans, see the array of
            premium features available, and upgrade your current subscription to unlock a world of limitless knowledge.
          </p>
          {auth.user.subscription_status == "active" && <div className=""></div>}
          <UpdatePricingTable
            enableAnnualBilling={enableAnnualBilling}
            setEnableAnnualBilling={setEnableAnnualBilling}
            handleCheckoutButton={handleCheckoutButton}
            currentTier={auth.user.subscription_tier}
            currentFrequency={auth.user.subscription_frequency}
            disableButtons={disableCheckoutButton}
          />
        </div>
      </div>
      <ActionModalInfo
        title="Subscription Update"
        BodyText={modalBodyText}
        show={showModal}
        HandleOnClose={handleOnClose}
        HandleOnAction={handleModalActionButton}
        ActionButtonText="Buy Now"
        CloseButtonText="Close"
      />
    </>
  );
}
